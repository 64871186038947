//
//Library Imports
import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";
import { setConfiguration } from "react-grid-system";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// Import Images
import Panasonic from "./images/panasonic.svg";
import Fuji from "./images/fujifilm.svg";
import Murata from "./images/murata.svg";
import Nissay from "./images/nissay.png";
import Honda from "./images/honda.svg";
import Loreal from "./images/loreal.svg";
import Mitsubu from "./images/mitsubi.svg";
import Konica from "./images/konica.svg";
import Rio from "./images/rio.svg";
import Kaneka from "./images/kaneka.svg";
import Bridgestone from "./images/bridgestone.svg";
import Gsk from "./images/gsk.svg";
import Beiersdorf from "./images/beiersdorf.svg";
import Nec from "./images/nec.svg";
import Clariant from "./images/clariant.svg";
import JxMetal from "./images/jxMetal.svg";
import Jcb from "./images/jcb-logo.svg";
import Upm from "./images/upm.svg";
import Image from "next/image";

const Marquee = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const MarqueeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 5.02px 10.03px 0px #2f2b431a;
  height: 80px;
  width: 183px;
  margin: 10px;
`;

function ClientCarousel() {
  return (
    <>
      <Hidden xs>
        <Container>
          <Row>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <Marquee>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Panasonic}
                    width={100}
                    height={100}
                    alt="Panasonic"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Fuji}
                    width={100}
                    height={100}
                    alt="Fuji"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Murata}
                    width={100}
                    height={100}
                    alt="Murata"
                  />
                </MarqueeItem>
                <MarqueeItem>

                  
                  <Image
                    as="image"
                    priority={true}
                    src={Nissay}
                    width={105}
                    height={26}
                  /> 
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Honda}
                    width={100}
                    height={100}
                    alt="Honda"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Loreal}
                    width={100}
                    height={100}
                    alt="Loreal"
                  />
                </MarqueeItem>
              </Marquee>
            </Col>

            <Col xl={12} lg={12} sm={12} xs={12}>
              <Marquee>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Mitsubu}
                    width={100}
                    height={70}
                    alt="Mitsubu"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Konica}
                    width={100}
                    height={100}
                    alt="Konica"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Rio}
                    width={100}
                    height={100}
                    alt="Rio"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Kaneka}
                    width={100}
                    height={100}
                    alt="Kaneka"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Bridgestone}
                    width={100}
                    height={100}
                    alt="Bridgestone"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Gsk}
                    width={50}
                    height={50}
                    alt="Gsk"
                  />
                </MarqueeItem>
              </Marquee>
            </Col>

            <Col xl={12} lg={12} sm={12} xs={12}>
              <Marquee>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={JxMetal}
                    width={100}
                    height={70}
                    alt="JxMetal"
                  />
                  {/* <Icon src={Rohm} /> */}
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Upm}
                    width={60}
                    height={100}
                    alt="Upm"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Jcb}
                    width={50}
                    height={50}
                    alt="Jcb"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Beiersdorf}
                    width={100}
                    height={100}
                    alt="Beiersdorf"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Nec}
                    width={100}
                    height={100}
                    alt="Nec"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Clariant}
                    width={100}
                    height={100}
                    alt="Clariant"
                  />
                </MarqueeItem>
              </Marquee>
            </Col>
          </Row>
        </Container>
      </Hidden>

      <Visible xs>
        <Container>
          <Row>
            <Col xl={12} lg={12} sm={12} xs={6}>
              <Marquee>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Panasonic}
                    width={100}
                    height={100}
                    alt="Panasonic"
                    sizes="(max-width: 768px) 80px, 100px"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Fuji}
                    width={100}
                    height={100}
                    alt="Fuji"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Murata}
                    width={100}
                    height={100}
                    alt="Murata"
                  />
                </MarqueeItem>
                <MarqueeItem>

                    <Image
                    as="image"
                    priority={true}
                    src={Nissay}
                    width={105}
                    height={26}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    style={{ width: "100%", height: "auto", maxWidth: "105px" }}
                    alt="Nissay"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Honda}
                    width={100}
                    height={100}
                    alt="Honda"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Loreal}
                    width={100}
                    height={100}
                    alt="Loreal"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Mitsubu}
                    width={100}
                    height={70}
                    alt="Mitsubu"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Konica}
                    width={100}
                    height={100}
                    alt="Konica"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Rio}
                    width={100}
                    height={100}
                    alt="Rio"
                  />
                </MarqueeItem>
              </Marquee>
            </Col>

            <Col xl={12} lg={12} sm={12} xs={6}>
              <Marquee>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Kaneka}
                    width={100}
                    height={100}
                    alt="Kaneka"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Bridgestone}
                    width={100}
                    height={100}
                    alt="Bridgestone"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Gsk}
                    width={50}
                    height={50}
                    alt="Gsk"
                  />
                </MarqueeItem>

                <MarqueeItem>
                  {/* <Icon src={Rohm} /> */}
                  <Image
                    as="image"
                    priority={true}
                    src={JxMetal}
                    width={100}
                    height={70}
                    alt="JxMetal"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Upm}
                    width={60}
                    height={100}
                    alt="Upm"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Jcb}
                    width={50}
                    height={50}
                    alt="Jcb"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Beiersdorf}
                    width={100}
                    height={100}
                    alt="Beiersdorf"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Nec}
                    width={100}
                    height={100}
                    alt="Nec"
                  />
                </MarqueeItem>
                <MarqueeItem>
                  <Image
                    as="image"
                    priority={true}
                    src={Clariant}
                    width={100}
                    height={100}
                    alt="Clariant"
                  />
                </MarqueeItem>
              </Marquee>
            </Col>
          </Row>
        </Container>
      </Visible>
    </>
  );
}

export default ClientCarousel;
